import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import Select from "react-select";
import whatsappIcon from "../../assets/images/Whatsapp.png";
import "./JoinIlaqa.css";
import { GetAreaNames, GetBuildingNames, GetWhatsappLink } from "../../api/api";
import logo from "../../assets/images/logo copy.png";
function JoinIlaqa() {
  const navigate = useNavigate();

  const [area, setArea] = useState("");
  const [societyName, setSocietyName] = useState("");

  const [areas, setAreas] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [whatsappLink, setWhatsappLink] = useState("");

  // const [location, setlocation] = useState("");
  const customStyles = {
    control: (provided) => ({
      ...provided,

      marginBottom: "15px",
      backgroundColor: "#eaf2fc",
      borderRadius: "10px",
      boxSizing: "border-box",
      border: "none",
    }),

    menu: (provided) => ({
      ...provided,
      borderRadius: "10px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000", // Customize as needed
    }),
  };
  const optionsBuildings = buildings.map((building) => ({
    value: building.buildingId,
    label: building.name,
  }));

  // Handle the change event
  const handleChangeBuildings = (selectedOption) => {
    setSocietyName(selectedOption.value);
  };

  const optionsAreas = areas?.map((area) => ({
    value: area.areaId,
    label: area.areaName,
  }));
  // function hexToString(hex) {
  //   return hex
  //     .match(/.{1,2}/g)
  //     .map((byte) => String.fromCharCode(parseInt(byte, 16)))
  //     .join("");
  // }
  // useEffect(() => {
  //   const val = hexToString(id);

  //   setlocation(val);
  // }, []);

  useEffect(() => {
    const getAreas = async () => {
      const data = await GetAreaNames();
      setAreas(data);
    };
    getAreas();
  }, []);
  useEffect(() => {
    if (societyName !== "") {
      const getWhatsappLink = async () => {
        const link = await GetWhatsappLink(area, societyName);
        setWhatsappLink(link.whatsappLink);
      };
      getWhatsappLink();
    }
  }, [societyName]);

  const handleAreaChange = async (selectedOption) => {
    setArea(selectedOption.value);
    const data = await GetBuildingNames(selectedOption.value);
    setBuildings(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setArea("");
    setSocietyName("");

    if (whatsappLink !== "") {
      window.open(whatsappLink, "_blank");
    }
  };

  return (
    <div className="join-ilaqa">
      <div className="join-ilaqa-top">
        <div className="join-ilaqa-header">
          <img
            src={logo}
            onClick={() => {
              navigate("/");
            }}
          ></img>
        </div>
        <div className="join-ilaqa-top-section">
          <h1 className="join-ilaqa-top-section-h1">iLaqa</h1>
          <h2 className="join-ilaqa-top-section-h2">
            Your
            <span className="join-ilaqa-top-section-h2-span"> One stop </span>
            solution to all services
          </h2>
          <p className="join-ilaqa-top-section-p">
            Experience the perfect blend of
            <strong> Quality, Convenience, & Reliability</strong> with our
            expertly curated suite of over <strong>40+ services</strong>,
            proudly supported by a vetted network of
            <strong>trusted vendors.</strong>
          </p>
        </div>
      </div>

      <div
        className=""
        style={{
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h2 style={{ fontSize: "14px", textAlign: "center" }}>
          <span style={{ color: "red" }}>*</span> Please enter all the following
          details to get the WhatsApp link for your iLaqa
        </h2>
        <form onSubmit={handleSubmit}>
          <label>
            Area
            <Select
              value={optionsAreas.find((option) => option.value === area)}
              onChange={handleAreaChange}
              options={optionsAreas}
              placeholder="Select Area"
              isSearchable
              styles={customStyles}
            />
          </label>

          <label>
            Society Name
            <Select
              value={optionsBuildings.find(
                (option) => option.value === societyName
              )}
              onChange={handleChangeBuildings}
              options={optionsBuildings}
              placeholder="Select Society Name"
              isSearchable
              styles={customStyles}
            />
          </label>

          <div className="whtsapp-btn-wrapper">
            <button className={`whatsapp-btn ${!societyName && "disable"}`}>
              <>
                <img src={whatsappIcon}></img>
                <span> Get WhatsApp Link </span>
              </>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
export default JoinIlaqa;
