import React, { useState } from "react";
import "./LosServices.css";
import { useLocation } from "react-router-dom";
import LosServiceCard from "../../components/LOS/LosService/LosServiceCard";
import LosServicesTopbar from "../../components/LOS/LosService/LosServicesTopbar";

function LosServices() {
  const { state: serviceCatagory } = useLocation();

  const [fiteredServices, setFilteredServices] = useState(
    serviceCatagory.services
  );
  const filterServices = (value) => {
    if (value === "") {
      setFilteredServices(serviceCatagory.services);
      return;
    }
    const filterBySearch = serviceCatagory.services.filter((item) => {
      if (item.serviceName.toLowerCase().includes(value.toLowerCase())) {
        return item;
      }
    });
    setFilteredServices(filterBySearch);
  };
  return (
    <div className="Los-Services-list">
      <LosServicesTopbar
        title={serviceCatagory.type}
        filterServices={filterServices}
      ></LosServicesTopbar>
      {fiteredServices.map((service) => {
        return <LosServiceCard service={service}></LosServiceCard>;
      })}
    </div>
  );
}

export default LosServices;
