import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import VendorBlacklist from "./Pages/Vendor-Blacklist/VendorBlacklist";
import "./App.css";
import HeaderLayout from "./route/HeaderLayout";
import JoinIlaqa from "./Pages/JoinIlaqa/JoinIlaqa";
import HomeAdmin from "./adminpages/HomeAdmin/HomeAdmin";
import CreateCluster from "./adminpages/CreateCluster/CreateCluster";
import EditCluster from "./adminpages/EditCluster/EditCluster";
import GenerateLFLink from "./adminpages/GenerateLFLink/GenerateLFLink";
import VendorBlacklistadmin from "./adminpages/VendorBlacklist/VendorBlacklistadmin";
import { useState } from "react";
import Shops from "./Pages/Shops/Shops";
import CreateShop from "./adminpages/CreateShops/CreateShop";
import EmergencyContactForm from "./adminpages/AddEmergency/EmergencyAdd";
import EmergencyContacts from "./Pages/Emergency/EmergencyContacts";
import AdminDashboard from "./adminpages/AdminDashBoard/AdminDashBoard";
import AddLosAdmin from "./adminpages/LOS/AddLosAdmin";
import AdminLOS from "./adminpages/LOS/AdminLOS";
import AddVendor from "./admincomponents/VendorBlacklist/AddVendor";
import LosServiceType from "./Pages/Los/LosServiceType";
import LosServices from "./Pages/Los/LosServices";
import AdminLayout from "./route/AdminLayout";
import AboutUs from "./Pages/AboutUs/aboutUs/AboutUs";
import WhoWeAre from "./Pages/AboutUs/whoWeAre/WhoWeAre";
import OurJourney from "./Pages/AboutUs/ourJourney/OurJourney";
import OurCeo from "./Pages/AboutUs/ourCeo/OurCeo";
import AboutUsHeaderLayout from "./route/AboutUsHeaderLayout";
import LosSubServices from "./Pages/Los/LosSubServices";
import EditLosAdmin from "./adminpages/LOS/EditLosAdmin";
import HeroService from "./Pages/HeroService/HeroService";
import ShopHome from "./adminpages/ShopHome/ShopHome";
import EmergencyHome from "./adminpages/EmergencyHome/EmergencyHome";
import PopupHandler from "./components/PopUpHandler";
import ScrollToTop from "./components/Reusable/ScrollToTop";
import ReferralFormAdmin from "./adminpages/Referral/ReferralFormAdmin";

function App() {
  const [clusterdata, setClusterData] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const handleFormSubmit = (shopData) => {
    console.log("Shop Data:", shopData);
  };
  const handleEmergencySubmit = (emergency) => {
    console.log("Emergency Data:", emergency);
  };

  return (
    <div>
      <BrowserRouter>
        <PopupHandler
          isPopupVisible={isPopupVisible}
          setIsPopupVisible={setIsPopupVisible}
        />
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <div className="header-hero-wrapper">
                <Home />
              </div>
            }
          ></Route>
          <Route path="/join" element={<JoinIlaqa></JoinIlaqa>}></Route>
          <Route element={<HeaderLayout />}>
            <Route path="/hero-service" element={<HeroService />}></Route>
            <Route
              path="/vendor-blacklist"
              element={<VendorBlacklist />}
            ></Route>
            <Route path="/shops" element={<Shops />}></Route>
            <Route path="/emergency" element={<EmergencyContacts />}></Route>
            <Route
              path="/LosTypes"
              element={<LosServiceType></LosServiceType>}
            ></Route>
            <Route
              path="/LosServices"
              element={<LosServices></LosServices>}
            ></Route>
            <Route
              path="/LosSubServices"
              element={<LosSubServices></LosSubServices>}
            ></Route>
          </Route>
          
        <Route path="/aboutus" element={<AboutUsHeaderLayout />}>
          <Route index element={<AboutUs></AboutUs>}></Route>
          <Route path="ourjourney" element={<OurJourney />}></Route>
          <Route path="ourceo" element={<OurCeo />}></Route>
        </Route>

          {/* {Admin Routes} */}
          <Route path="/61646d696e" element={<AdminDashboard />}></Route>

          <Route element={<AdminLayout />}>
            <Route path="/61646d696e/shops" element={<ShopHome />}></Route>
            <Route
              path="/61646d696e/shops/shopAdd"
              element={<CreateShop onSubmit={handleFormSubmit} />}
            ></Route>
            <Route
              path="/61646d696e/emergency"
              element={<EmergencyHome />}
            ></Route>
            <Route
              path="/61646d696e/emergency/emergencyAdd"
              element={
                <EmergencyContactForm onSubmit={handleEmergencySubmit} />
              }
            ></Route>
            <Route
              path="/61646d696e/cluster-manage"
              element={
                <HomeAdmin
                  data={clusterdata}
                  setData={setClusterData}
                ></HomeAdmin>
              }
            ></Route>
            <Route
              path="/61646d696e/homeAdmin/edit-cluster"
              element={<EditCluster setData={setClusterData} />}
            ></Route>
            <Route
              path="/61646d696e/homeAdmin/create-cluster"
              element={<CreateCluster setData={setClusterData} />}
            ></Route>
            <Route
              path="/61646d696e/homeAdmin/vendor-blacklist-admin"
              element={<VendorBlacklistadmin></VendorBlacklistadmin>}
            ></Route>
            <Route
              path="/61646d696e/homeAdmin/vendor-blacklist-admin/add"
              element={<AddVendor></AddVendor>}
            ></Route>

            <Route
              path="/61646d696e/los"
              element={<AdminLOS></AdminLOS>}
            ></Route>
            <Route
              path="/61646d696e/los/add"
              element={<AddLosAdmin></AddLosAdmin>}
            ></Route>
            <Route
              path="/61646d696e/los/edit"
              element={<EditLosAdmin></EditLosAdmin>}
            ></Route>

            <Route
              path="/61646d696e/homeAdmin/generate-link"
              element={<GenerateLFLink></GenerateLFLink>}
            ></Route>
            <Route
              path="/61646d696e/referralForm"
              element={<ReferralFormAdmin></ReferralFormAdmin>}
            ></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
