import React from "react";
import "./LosServiceTypeCard.css";

import arrow from "../../../assets/images/forwordArrow.png.png";
import house from "../../../assets/images/house.svg";
import car from "../../../assets/images/voiture 1.svg";
import buffet from "../../../assets/images/buffet (1).svg";
import document from "../../../assets/images/documentation (1) 1.svg";
import { useNavigate } from "react-router-dom";
function LosServiceCard({ services }) {
  const navigate = useNavigate();
  const imgs = [house, car, buffet, document];
  return (
    <div className="los-cards-container ">
      {services?.map((service, i) => {
        return (
          <div
            className="los-card"
            key={`los-card-${i}`}
            onClick={() => {
              navigate("/LosServices", { state: service });
            }}
          >
            <img src={imgs[i]} alt="Vendor" className="los-type-image" />
            <h3 className="los-type-name">{service.type}</h3>

            <div>
              <p className="los-type-descri">{service.description}</p>
            </div>
            <button>
              <img src={arrow}></img>
            </button>
          </div>
        );
      })}
    </div>
  );
}

export default LosServiceCard;
