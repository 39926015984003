import React, { useEffect, useState } from "react";
import "./AddLosAdmin.css";
import { GetServices, PostService } from "../../api/api";
import { useNavigate } from "react-router-dom";

const AddLosAdmin = () => {
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [description, setDescription] = useState("");
  const [subServices, setSubServices] = useState([]);
  const [newSubService, setNewSubService] = useState({ name: "", details: [] });

  useEffect(() => {
    const getServiceData = async () => {
      const res = await GetServices();
      setServices(res);
    };
    getServiceData();
  }, []);

  const handleAddSubServiceDetail = () => {
    if (
      newSubService.details.some(
        (detail) => detail.name === "" || detail.value === ""
      )
    ) {
      // Prevent adding a new detail if any of the existing detail fields are empty
      return;
    }

    setNewSubService({
      ...newSubService,
      details: [...newSubService.details, { name: "", value: "" }],
    });
  };

  const handleAddSubService = () => {
    if (newSubService.name || newSubService.details.length > 0) {
      setSubServices([...subServices, newSubService]);
      setNewSubService({ name: "", details: [] });
    }
  };

  const handleEditSubServiceName = (index, name) => {
    setSubServices(
      subServices.map((subService, i) =>
        i === index ? { ...subService, name } : subService
      )
    );
  };

  const handleEditSubServiceDetail = (
    subServiceIndex,
    detailIndex,
    field,
    value
  ) => {
    setSubServices(
      subServices.map((subService, i) =>
        i === subServiceIndex
          ? {
              ...subService,
              details: subService.details.map((detail, j) =>
                j === detailIndex ? { ...detail, [field]: value } : detail
              ),
            }
          : subService
      )
    );
  };

  const handleSubmit = async () => {
    // Filter out any empty details before adding the subservice
    const validDetails = newSubService.details.filter(
      (detail) => detail.name !== "" && detail.value !== ""
    );

    // Automatically add the new sub-service if it has a name or valid details
    if (newSubService.name || validDetails.length > 0) {
      setSubServices([
        ...subServices,
        { ...newSubService, details: validDetails },
      ]);
      setNewSubService({ name: "", details: [] });
    }

    const newService = {
      serviceName,
      description: description,
      subService: [...subServices, { ...newSubService, details: validDetails }],
    };

    if (selectedType && serviceName && description) {
      await PostService(selectedType, newService);
      navigate("/61646d696e/los");
    }
  };

  return (
    <div className="add-service-container">
      <h2>Add New Service</h2>
      <label>
        Service Type:
        <select
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
        >
          <option value="" disabled>
            Select a Service Type
          </option>
          {services.map((service) => (
            <option key={service.type} value={service.type}>
              {service.type}
            </option>
          ))}
          <option value="newType">Add New Type</option>
        </select>
      </label>

      {selectedType === "newType" && (
        <input
          type="text"
          placeholder="Enter new service type"
          onChange={(e) => setSelectedType(e.target.value)}
        />
      )}

      <label>
        Service Name:
        <input
          type="text"
          value={serviceName}
          onChange={(e) => setServiceName(e.target.value)}
        />
      </label>

      <label>
        Description for service:
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </label>

      <div className="sub-service-container">
        <h3>Sub-Services</h3>
        {subServices.map((subService, index) => (
          <div key={index} style={{ width: "100%" }}>
            <h4>Subservice {index + 1}</h4>
            <h4>
              <input
                type="text"
                value={subService.name}
                onChange={(e) =>
                  handleEditSubServiceName(index, e.target.value)
                }
              />
            </h4>
            {subService.details.map((detail, i) => (
              <div key={i} className="sub-service-detail">
                <h5>{i + 1}.</h5>
                <input
                  type="text"
                  placeholder="Detail Name"
                  value={detail.name}
                  onChange={(e) =>
                    handleEditSubServiceDetail(index, i, "name", e.target.value)
                  }
                />
                <input
                  type="text"
                  placeholder="Detail Value"
                  value={detail.value}
                  onChange={(e) =>
                    handleEditSubServiceDetail(
                      index,
                      i,
                      "value",
                      e.target.value
                    )
                  }
                />
              </div>
            ))}
          </div>
        ))}

        <label
          style={{
            borderTop: "1px solid gray",
            paddingTop: "10px",
            width: "100%",
          }}
        >
          Sub-Service Name:
          <input
            type="text"
            value={newSubService.name}
            onChange={(e) =>
              setNewSubService({ ...newSubService, name: e.target.value })
            }
          />
        </label>
        <div
          style={{
            width: "100%",
            marginBlock: "10px",
            padding: "5px",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {newSubService.details.map((detail, i) => (
            <div
              key={i}
              className="sub-service-detail "
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                borderBottom: "1px solid gray",
                width: "100%",
              }}
            >
              <input
                type="text"
                placeholder="Detail Name"
                value={detail.name}
                onChange={(e) =>
                  setNewSubService({
                    ...newSubService,
                    details: newSubService.details.map((d, idx) =>
                      idx === i ? { ...d, name: e.target.value } : d
                    ),
                  })
                }
              />
              <input
                type="text"
                placeholder="Detail Value"
                value={detail.value}
                onChange={(e) =>
                  setNewSubService({
                    ...newSubService,
                    details: newSubService.details.map((d, idx) =>
                      idx === i ? { ...d, value: e.target.value } : d
                    ),
                  })
                }
              />
            </div>
          ))}
          <button onClick={handleAddSubServiceDetail}>Add Detail</button>
        </div>
        <button onClick={handleAddSubService}>Add Sub-Service</button>
      </div>

      <button onClick={handleSubmit}>Add Service</button>
      <button
        onClick={() => {
          navigate("/61646d696e/los");
        }}
      >
        Cancel
      </button>
    </div>
  );
};

export default AddLosAdmin;
