import React, { useEffect, useState } from "react";
import crossIcon from "../../assets/images/Cross Icon.png";
import whatsappIcon from "../../assets/images/Whatsapp.png";
import Select from "react-select";
import "./Modal.css";
import { GetAreaNames, GetBuildingNames, GetWhatsappLink } from "../../api/api";

const Modal = ({ setIsLink, isLink }) => {
  const [area, setArea] = useState("");
  const [societyName, setSocietyName] = useState("");

  const [areas, setAreas] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [whatsappLink, setWhatsappLink] = useState("");

  const customStyles = {
    control: (provided) => ({
      ...provided,

      marginBottom: "15px",
      backgroundColor: "#eaf2fc",
      borderRadius: "10px",
      boxSizing: "border-box",
      border: "none",
    }),

    menu: (provided) => ({
      ...provided,
      borderRadius: "10px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000", // Customize as needed
    }),
  };
  const optionsBuildings = buildings.map((building) => ({
    value: building.buildingId,
    label: building.name,
  }));

  // Handle the change event
  const handleChangeBuildings = async (selectedOption) => {
    setSocietyName(selectedOption.value);
  };
  useEffect(() => {
    if (societyName !== "") {
      const getWhatsappLink = async () => {
        const link = await GetWhatsappLink(area, societyName);
        setWhatsappLink(link.whatsappLink);
      };
      getWhatsappLink();
    }
  }, [societyName]);

  const optionsAreas = areas?.map((area) => ({
    value: area.areaId,
    label: area.areaName,
  }));
  useEffect(() => {
    const getAreas = async () => {
      const data = await GetAreaNames();

      setAreas(data);
    };
    getAreas();
  }, []);

  const handleAreaChange = async (selectedOption) => {
    setArea(selectedOption.value);
    const data = await GetBuildingNames(selectedOption.value);
    setBuildings(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setArea("");
    setSocietyName("");
    if (whatsappLink !== "") {
      window.open(whatsappLink, "_blank");
    }
  };
  useEffect(() => {
    if (isLink) {
      document.body.style.overflow = "hidden"; // Prevent scrolling
    } else {
      document.body.style.overflow = ""; // Revert to default
    }

    return () => {
      document.body.style.overflow = ""; // Revert to default
    };
  }, [isLink]);
  return (
    <div
      className="modal-overlay"
      onClick={(e) => {
        e.stopPropagation();
        setIsLink(false);
      }}
    >
      <div
        className="modal-content"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          className="close-btn"
          onClick={() => {
            setIsLink(false);
          }}
        >
          <img src={crossIcon}></img>
        </div>
        <h2>
          <span>*</span> Please enter all the following details to get the
          WhatsApp link for your iLaqa
        </h2>
        <form onSubmit={handleSubmit}>
          {/* <label>
            Area
            <select value={area} onChange={handleAreaChange}>
              <option style={{ maxWidth: "50px" }} value="">
                Select Area
              </option>
              {areas?.map((area) => (
                <option
                  style={{ maxWidth: "50px" }}
                  key={area.areaId}
                  value={area.areaId}
                >
                  {area.areaName}
                </option>
              ))}
            </select>
          </label>
          {area && (
            <label>
              Society Name
              <select
                value={societyName}
                onChange={(e) => setSocietyName(e.target.value)}
              >
                <option value="">Select Society Name</option>
                {buildings.map((building) => (
                  <option key={building.buildingId} value={building.buildingId}>
                    {building.name}
                  </option>
                ))}
              </select>
            </label>
          )} */}
          <label>
            Area
            <Select
              value={optionsAreas.find((option) => option.value === area)}
              onChange={handleAreaChange}
              options={optionsAreas}
              placeholder="Select Area"
              isSearchable
              styles={customStyles}
            />
          </label>

          <label>
            Society Name
            <Select
              value={optionsBuildings.find(
                (option) => option.value === societyName
              )}
              onChange={handleChangeBuildings}
              options={optionsBuildings}
              placeholder="Select Society Name"
              isSearchable
              styles={customStyles}
            />
          </label>

          <div className="whtsapp-btn-wrapper">
            <button className={`whatsapp-btn ${!whatsappLink && "disable"}`}>
              <>
                <img src={whatsappIcon}></img>
                <span> Get WhatsApp Link </span>
              </>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Modal;
