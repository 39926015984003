import React, { useState } from "react";
import "./Generate.css";
function GenerateLFLink() {
  const [inputString, setInputString] = useState("");
  const [hexOutput, setHexOutput] = useState("");
  const convertToHex = () => {
    const hex = inputString
      .split("")
      .map((char) => char.charCodeAt(0).toString(16))
      .join("");
    setHexOutput(`http://localhost:3001/join/${hex}`);
  };
  const copyToClipboard = () => {
    navigator.clipboard.writeText(hexOutput);
  };
  return (
    <div className="container">
      <input
        type="text"
        value={inputString}
        onChange={(e) => setInputString(e.target.value)}
        placeholder="Enter your string here"
        className="input"
      />
      <button onClick={convertToHex} className="button">
        Convert
      </button>
      <div>Here is the link for {inputString}</div>
      <div>{hexOutput}</div>
      <button onClick={copyToClipboard} className="button">
        Copy to Clipboard
      </button>
    </div>
  );
}

export default GenerateLFLink;
