import React from "react";
import "./Hero.css";
import Services from "../Services/Services";
import Modal from "../WhatsappLinkModal/Modal";
import { useNavigate } from "react-router-dom";

const Hero = ({ isLink, setIsLink }) => {
  const Navigate = useNavigate();
  return (
    <>
      <section className="hero">
        <div className="hero-text">
          <h1 className="ilaqa">iLaqa</h1>
          <h2 className="hero-heading">
            Your
            <span className="one-stop"> One stop</span> solution to all services
          </h2>
          <ul>
            <li>Verified and recommended vendors</li>
            <li>Talk to a real person for queries and booking </li>

            <li>Post service follow ups and refunds</li>
            <li>
              30+ services, documentation, laptop, mesh, grills, vehicle,cab
            </li>
          </ul>
        </div>
        <s>
          <Services />
        </s>
        <button
          onClick={() => {
            Navigate("/LosTypes");
          }}
          className="heroListButton"
        >
          List of services
        </button>
        <button
          onClick={() => {
            setIsLink(true);
          }}
          className="joinButton"
        >
          Join my iLaqa
        </button>
      </section>

      {isLink && <Modal setIsLink={setIsLink} isLink={isLink}></Modal>}
    </>
  );
};

export default Hero;
