import React, { useEffect, useState } from "react";
import frame22 from "../../assets/images/Los illustration (1).svg";
import "./LosServiceType.css";
import LosServiceTypeCard from "../../components/LOS/LOSTypes/LosServiceTypeCard";
import LosTypeSearch from "../../components/LOS/LOSTypes/LosTypeSearch";
import { GetServices } from "../../api/api.js";
import LosServiceCard from "../../components/LOS/LosService/LosServiceCard.jsx";
import Loader from "../../components/Loader/GenralLoader.js";
function LosServiceType() {
  const [services, setServices] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredServices, setFilteredServices] = useState([]);

  useEffect(() => {
    const getServiceData = async () => {
      const res = await GetServices();

      setServices(res);
    };
    getServiceData();
  }, []);
  useEffect(() => {
    const filteredServicestemp = services.flatMap((category) =>
      category.services
        .filter(
          (service) =>
            service.serviceName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            service.description
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
        )
        .map((service) => ({
          _id: service._id,
          serviceName: service.serviceName,
          description: service.description,
          subService: service.subService,
          categoryType: category.type, // Include category type if needed
        }))
    );

    setFilteredServices(filteredServicestemp);
  }, [searchQuery]);

  return (
    <div style={{ paddingInline: "10px" }}>
      <section className="Los-types-img">
        <img src={frame22} alt="Illustration" />
      </section>
      <LosTypeSearch
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filteredServices={filteredServices}
      ></LosTypeSearch>

      {!searchQuery ? (
        <>
          <h2 style={{ marginTop: "5%" }}>List of Services</h2>
          {services.length ? (
            <LosServiceTypeCard services={services}></LosServiceTypeCard>
          ) : (
            <Loader></Loader>
          )}
        </>
      ) : (
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {filteredServices.map((service) => {
            return <LosServiceCard service={service}></LosServiceCard>;
          })}
        </div>
      )}
    </div>
  );
}

export default LosServiceType;
