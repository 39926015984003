import React from "react";
import "./LosSubServices.css";
import arrow from "../../assets/images/ArrowBAck.svg";
import { useLocation, useNavigate } from "react-router-dom";
import LosSubServiceCard from "../../components/LOS/LosSubService/LosSubServiceCard";
function LosSubServices() {
  const { state: service } = useLocation();
  const navigate = useNavigate();

  return (
    <div className="los-subservice">
      <div className="los-subservice-top">
        <button
          className="servicelist-backbtn"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={arrow}></img>
        </button>
        <h1 className="servicelist-title los-subservice-to-h1">
          {service.serviceName}
        </h1>
      </div>
      <p className="los-subservice-top-descri">{service.description}</p>
      {service.subService.map((subservice) => {
        return <LosSubServiceCard subservice={subservice}></LosSubServiceCard>;
      })}
      <a
        href={`https://wa.me/+917032512444?text=Hello iLaqa, I want to book ${service.serviceName} service `}
      >
        <button className="los-book-now">Book Now</button>
      </a>
    </div>
  );
}

export default LosSubServices;
