import Header from "../../components/Header/Header";
import Hero from "../../components/Hero/Hero";
import Testimonials from "../../components/Testimonials/Testimonials";
import Footer from "../../components/Footer/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Home.css";
import OtherServicesScreen from "../../components/Services/OtherServices";
import Help from "../../components/Helps/Help";
import ContactUs from "../../components/ContactUsScreen/ContactUs";
import ComingSoon from "../../components/ComingSoon/ComingSoon";
import { useState } from "react";

function Home() {
  const [isComingSoon, setIsComingSoon] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const openModal = () => {
    setIsComingSoon(true);
  };
  const closeModal = () => {
    setIsComingSoon(false);
  };
  return (
    <div className="App">
      <Header openModal={openModal} />
      <Hero isLink={isLink} setIsLink={setIsLink} />
      <Testimonials />
      <ContactUs />
      <Help />
      <OtherServicesScreen openModal={openModal} />
      {/* <Footer /> */}
      {isComingSoon && (
        <ComingSoon closeModal={closeModal} setIsLink={setIsLink} />
      )}
    </div>
  );
}

export default Home;
