import React from "react";
import logo from "../../assets/images/logo.png";
import "./Headeradmin.css";
import { useNavigate } from "react-router-dom";
function HeaderAdmin() {
  const navigate = useNavigate();
  return (
    <div className="header-container">
      <img
        src={logo}
        className="logoa"
        alt="Logo"
        onClick={() => {
          navigate("/61646d696e");
        }}
      />
    </div>
  );
}

export default HeaderAdmin;
