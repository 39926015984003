import React from "react";
import "./LosServiceCard.css";
import arrow from "../../../assets/images/forwordArrow.png.png";
import { useNavigate } from "react-router-dom";
function LosServiceCard({ service }) {
  const navigate = useNavigate();
  return (
    <div
      className="los-service-card"
      onClick={() => {
        navigate("/LosSubServices", { state: service });
      }}
    >
      <div className="los-service-card-text">
        <h2>{service.serviceName}</h2>
        <p>{service.description}</p>
      </div>
      <div>
        <button>
          <img src={arrow}></img>
        </button>
      </div>
    </div>
  );
}

export default LosServiceCard;
